<template>
  <div>
    <NavBar class=" fixed top-0  left-0 right-0"></NavBar>
    <!-- Mobile -->
    <div style="background-color :#F4F4F4;" class="grid grid-cols-9 gap-2 mt-16  pagewidth-max sm:hidden shadow-md pb-5">
      <PieChartPlotly class=" col-span-3 sm:w-56 w-24 h-24 justify-self-center  mt-10"
        :pieData="getPortfolioWithPieChartDatav2()" />
      <div class="mt-5 col-span-3">
        <h1 style="" class="oeno_title_mobile font-bold font-Montserrat mt-5">
          Your Portfolio
        </h1>

        <div
          v-for="total in getTotals"
          v-bind:key="total.currency"
          class="flex"
        >
          <div class="mt-3">
            <span style="" class="oeno_title_mobile font-bold">{{
              currencyFormatter(total.total)
            }} {{ total.currency }}</span><br clear="all" />
            <span :class="[
              (total.changePrice >= 0)
                ? 'oeno_portfolio_price_up_mobile'
                : 'oeno_portfolio_price_down_mobile',
            ]">{{ getChangeTotal(total) }}</span>
          </div>
          <div class=""><span class="oeno_title_mobile font-bold mt-"></span></div>
        </div>
        <button class="oeno_title_mobile font-bold mt-4" type="button" data-te-collapse-init data-te-ripple-init
          data-te-ripple-color="light" data-te-target="#collapseExample" aria-expanded="false"
          aria-controls="collapseExample">Breakdown <span class="text-sm font-bold visible !hidden" id="collapseExample"
            data-te-collapse-item>˅</span>
        </button>
        <div class="!visible hidden" id="collapseExample" data-te-collapse-item>
          <div
            class="rows-span-3 space-y-1 oeno_title_mobile font-semibold w-36"
            v-for="(item, index) in pieChartReturnData()"
            v-bind:key="index"
          >
            <div class="flex justify-between">
              <p style="" class="w-3/4">{{ index }}</p>
              <p style="" class="">{{ item.toFixed(1) }}%</p>
            </div>
          </div>
          <div
            class="oeno_title_mobile text-center text-xl"
            type="button"
            data-te-collapse-init
            data-te-ripple-init
            data-te-ripple-color="light"
            data-te-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            ^
          </div>
        </div>
      </div>


      <div class="sm:justify-center  mt-10 col-span-3">
        <h1 class=" oeno_title_mobile font-bold">{{ getUserName }}</h1>
        <h3 class="oeno_title_mobile font-bold ml-8">
          {{ getUserReference }}<br /><br />
        </h3>
      </div>
    </div>
    <!-- Mobile -->

    <div class="sm:hidden">
      <div v-if="$store.state.portfolio">
        <div class="mt-3">

          <!-- <div class="mt-10 mb-10">
                                          <h1 @click="showAnalytics()" class="text-3xl oeno_portfolio_title font-bold float-right cursor-pointer">
                                            Analytics</h1>
                                          <h1 class="text-5xl">Portfolio</i></h1>


                                        </div> -->
          <div class="">
            <div class="">
              <div
                style="border-bottom: 0.5px solid #000000"
                class="grid grid-cols-8 pb-2"
              >
                <p class="oeno_col_heading"></p>
                <p class="oeno_col_title_mobile col-span-3 col-start-3">
                  Name/Quantity
                </p>

                <p class="oeno_col_title_mobile col-span-3">Market Value</p>

                <p class="oeno_col_title_mobile"></p>
              </div>

              <div
                class="mt-2"
                v-for="investment in $store.state.portfolio"
                v-bind:key="investment.id"
              >
                <div
                  v-for="item in investment.items"
                  v-bind:key="item.id"
                  class="grid grid-cols-8 gap-4 cursor-pointer mt-5"
                  @click="showDetail(item.wine_id, investment.id)"
                >
                  <div class="col-span-2 ml-10 mt-0">
                    <img
                      :src="wineImage(item)"
                      class="w-6 h-80"
                      onerror="this.style.display='none'"
                    />
                  </div>
                  <div class="col-span-3 w-36">
                    <span class="oeno_title_mobile font-bold">{{
                      item.wines.name
                    }}</span
                    ><br />
                    <span class="oeno_title_mobile"
                      ><i>{{ winary(item) }}</i></span
                    >
                    <br />
                    <span class="oeno_title_mobile font-bold">{{
                      showTotalBottle(item)
                    }}</span
                    ><br />
                    <span class="oeno_title_mobile"
                      ><i>{{ showquantity(item) }}</i></span
                    >
                  </div>
                  <div class="col-span-3">
                    <div class="col-span-1">
                      <span class="oeno_currency_price_mobile font-bold"
                        >{{
                          currencyFormatter(getPurchase(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }}</span
                      >
                      <br /><span
                        :class="[
                          priceUp(investment, item)
                            ? ' oeno_portfolio_price_up_mobile'
                            : ' oeno_portfolio_price_down_mobile',
                        ]"
                        >{{ getChange(investment, item) }}</span
                      >
                    </div>
                    <br />
                    <div
                      class="grid justify-items-center sm:cols-span-2 gap-2 col-span-2"
                    >
                      <button
                        style="border-color;: #000000"
                        v-if="item.brokerages.length > 0"
                        class="w-20 h-6 rounded-full border border-black align-middle oeno_button_mobile_white"
                      >
                        Sell Now
                      </button>
                      <button
                        v-if="item.brokerages.length == 0"
                        class="w-20 h-6 oeno_button_mobile_black"
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <secondary-navbar title="Your Portfolio" :showbroker="true" />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip />
      </div>

      <div class="col-span-8">
        <span id="download_loader" v-if="!$store.state.portfolio"></span>

        <div v-if="$store.state.portfolio">
          <div class="">
            <!-- <div class="mt-10 mb-10">
                                          <h1 @click="showAnalytics()" class="text-3xl oeno_portfolio_title font-bold float-right cursor-pointer">
                                            Analytics</h1>
                                          <h1 class="text-5xl">Portfolio</i></h1>


                                        </div> -->
            <div class="">
              <div class="">
                <div
                  style="border-bottom: 0.5px solid #000000"
                  class="grid grid-cols-11 pb-2"
                >
                  <p class="oeno_col_heading"></p>
                  <p class="oeno_col_heading col-span-3">Name</p>

                  <p class="oeno_col_heading col-span-2">Quantity</p>

                  <p class="float-right oeno_col_heading col-span-2">
                    Purchase Price
                  </p>

                  <p class="oeno_col_heading">Market Value</p>

                  <p class="oeno_col_heading"></p>
                </div>

                <div
                  class="mt-2"
                  v-for="investment in $store.state.portfolio"
                  v-bind:key="investment.id"
                >
                  <div
                    v-for="item in investment.items"
                    v-bind:key="item.id"
                    class="grid grid-cols-11 gap-4 mt-5"
                  >
                    <div class="ml-10 mt-0">
                      <img
                        :src="wineImage(item)"
                        class="oeno_portfolio_wineimage"
                        onerror="this.style.display='none'"
                      />
                    </div>
                    <div class="col-span-3">
                      <span class="oeno_wine_name">{{ item.wines.name }}</span
                      ><br />
                      <span class="oeno_portfolio_winequantity_case_bottle"
                        ><i>{{ winary(item) }}</i></span
                      ><br />
                    </div>
                    <div class="col-span-2">
                      <span class="oeno_wine_name">{{
                        showTotalBottle(item)
                      }}</span
                      ><br />
                      <span class="oeno_portfolio_winequantity_case_bottle"
                        ><i>{{ showquantity(item) }}</i></span
                      >
                    </div>
                    <div class="col-span-2">
                      <span class="oeno_wine_name"
                        >{{
                          currencyFormatter(getPurchase(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }}</span
                      >
                      <br />
                    </div>
                    <div class="col-span-1">
                      <span class="oeno_wine_name"
                        >{{
                          currencyFormatter(getPrice(investment, item))
                        }}&nbsp;{{ getCurrency(investment, item) }}</span
                      >
                      <br /><span
                        :class="[
                          priceUp(investment, item)
                            ? ' oeno_portfolio_price_up'
                            : ' oeno_portfolio_price_down',
                        ]"
                        >{{ getChange(investment, item) }}</span
                      >
                    </div>
                    <div
                      class="grid justify-items-center sm:cols-span-2 gap-2 col-span-2"
                    >
                      <button
                        class="md:w-48 h-10 rounded-full border border-black align-middle oeno_portfolio_title_small zindex-10"
                        @click="sellwine(item.wine_id, investment.id)"
                        v-if="item.brokerages.length == 0"
                      >
                        Sell&nbsp;Wine
                      </button>

                      <button
                        class="md:w-48 h-10 rounded-full border border-black align-middle oeno_portfolio_title_small zindex-10"
                        @click="sellOrder(item.wine_id, investment.id)"
                        v-if="item.brokerages.length > 0"
                      >
                        View&nbsp;Sell Order
                      </button>

                      <button
                        v-if="item.brokerages.length == 0"
                        class="md:w-32 h-10 oeno_button_black"
                        @click="showDetail(item.wine_id, investment.id)"
                      >
                        View
                      </button>
                    </div>
                    <div
                      class="col-span-11"
                      style="border-bottom: 0.5px solid #000000"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { HTTP } from "@/axios.js";
import moment from "moment";
import PieChartPlotly from "../../../components/PieChartPlotly.vue";
// import 'flowbite/dist/flowbite.js'
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  data() {
    return {
      menuOpen: false,
      pi: [],
      colors: [
        "#F27C00",
        "#FEA400",
        "#91B508",
        "#aa57ea",
        "#ce7805",
        "#b384d2",
        "#ffeacc",
        "#ffcc7b",
        "#ce7805",
        "#F27C00",
        "#FEA400",
        "#91B508",
        "#aa57ea",
        "#ce7805",
        "#b384d2",
        "#ffeacc",
        "#ffcc7b",
        "#ce7805",
        "#F27C00",
        "#FEA400",
        "#91B508",
        "#aa57ea",
        "#ce7805",
        "#b384d2",
        "#ffeacc",
        "#ffcc7b",
        "#ce7805",
        "#F27C00",
        "#FEA400",
        "#91B508",
        "#aa57ea",
        "#ce7805",
        "#b384d2",
        "#ffeacc",
        "#ffcc7b",
        "#ce7805",
      ],
    };
  },
  components: {
    PieChartPlotly,
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },

  mounted() {
    this.$nextTick(() => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.token,
      };

      HTTP.get("/api/get-portfolio-full", { headers: headers }).then(
        (response) => {
          if (response.data.statuscode == 200) {

            console.log(response.data.data.investment);
            this.$store.dispatch("storePortfolioData", {
              portfoliodata: response.data.data,
            });
          }
        }
      );
    });
  },
  computed: {
    getUserName() {
      var userName = "";
      if (this.$store.state.user) {
        userName =
          this.$store.state.user.firstname +
          " " +
          this.$store.state.user.surname;
      }
      return userName;
    },
    getUserReference() {
      var reference = "";
      if (this.$store.state.user) {
        reference = "(" + this.$store.state.user.unique_number + ")";
      }
      return reference;
    },
    getUserBroker() {
      var broker = "";
      if (this.$store.state.user) {
        broker =
          this.$store.state.user.brokerName +
          " " +
          this.$store.state.user.brokerEmail;
      }
      return broker;
    },
    getTotals() {
      const values = [];

      if (this.$store.state.portfolio) {
        this.$store.state.portfolio.forEach((investment) => {
          investment.items.forEach((item) => {
            var itemPrice = this.getValuesForItem(investment, item);

            var pos = -1;
            for (let index = 0; index < values.length; index++) {
              if (values[index].currency == itemPrice.currency) {
                pos = index;
              }
            }

            if (pos > -1) {
              values[pos].total =
                values[pos].total + parseFloat(itemPrice.total);
              values[pos].changePrice =
                values[pos].changePrice + parseFloat(itemPrice.changePrice);
            } else {
              values.push({
                currency: itemPrice.currency,
                changePrice: parseFloat(itemPrice.changePrice),
                total: itemPrice.total,
              });
            }
          });
        });
      }
      return values;
    },
  },
  methods: {
    pieChartReturnData() {
      let uniqueNames = {};
      let totalValue = 0;
      let temp = this.getPortfolioWithPieChartDatav2();
      temp.forEach((obj) => {
        totalValue += obj.price;
        if (!uniqueNames[obj.labels]) {
          uniqueNames[obj.labels] = obj.price;
        } else {
          uniqueNames[obj.labels] += obj.price;
        }
      });

      let namePercentage = {};
      for (let name in uniqueNames) {
        namePercentage[name] = (uniqueNames[name] / totalValue) * 100;
      }
      return namePercentage;
    },
    getPortfolioWithPieChartDatav2() {
      let retValArray = [];
      let colorArray = [];
      let pos = 0;
      let coloPos = -1;


      if (this.$store.state.portfolio) {
        this.$store.state.portfolio.forEach((investment) => {

          investment.items.forEach((item) => {

            pos = pos + 1;

            coloPos = colorArray.indexOf(item.wines.winery.region.name);
            if (coloPos == -1) {
              colorArray.push(item.wines.winery.region.name);
              coloPos = colorArray.length - 1;
            }

            retValArray.push({
              investment: investment,
              item: item,
              color: this.colors[coloPos],
              labels: item.wines.name,
              price: Math.round(this.getPrice(investment, item)),
            });
          });
        });
      }
      return retValArray;
    },


    sellwine(id, orderId) {
      this.$router.push({
        name: "page--sell",
        params: { id: id, orderId: orderId },
      });
    },
    sellOrder(id, orderId) {
      this.$router.push({
        name: "page--sell--order",
        params: { id: id, orderId: orderId },
      });
    },
    logout() {
      this.$store.dispatch("login", { token: "" });
      this.$router.push("/login");
      this.$store.dispatch("storePortfolioData", {});
    },
    showDetail(id, orderId) {
      this.$router.push({
        name: "page-portfolio-detail",
        params: { id: id, orderId: orderId },
      });
    },
    showAnalytics() {
      this.$router.push("/analytics");
    },
    winary(wine) {
      var winary = "n/a";
      var vintage = "";
      var size = "";

      if (wine.wines) {
        if (wine.wines.winery) {
          vintage = wine.wines.vintage;
          if (wine.wines.winery.name) {
            winary = wine.wines.winery.name;
          }
          if (wine.wines.size.name) {
            size = wine.wines.size.name;
          }
        }
      }

      winary = winary + " " + vintage;

      if (size != "") {
        winary = winary + " " + size + "ML";
      }

      return winary;
    },
    showTotalBottle(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity * unitsPerCase > 1) {
        retStr = quantity * unitsPerCase + " Bottles";
      } else {
        retStr = quantity * unitsPerCase + " Bottle";
      }

      return retStr;
    },
    showquantity(wine) {
      var quantity = 0;
      var unitsPerCase = 0;
      var retStr = "";

      if (wine.quantity) {
        quantity = parseInt(wine.quantity);
      }
      if (wine.wines) {
        if (wine.wines.units_per_case) {
          unitsPerCase = parseInt(wine.wines.units_per_case);
        }
      }

      if (quantity > 0) {
        if (quantity > 1) {
          retStr = quantity + " Cases";
        } else {
          retStr = quantity + " Case";
        }
      }

      if (unitsPerCase > 0) {
        if (unitsPerCase > 1) {
          retStr = retStr + " (" + unitsPerCase + " bottles per case)";
        } else {
          retStr = retStr + " (" + unitsPerCase + " bottle per case)";
        }
      }

      return retStr;
    },

    wineImage(wine) {
      var wineimageurl = process.env.VUE_APP_WINE_DUMMY_IMAGE;
      if (wine.wines) {
        if (wine.wines.images) {
          if (wine.wines.images[0]) {
            wineimageurl =
              process.env.VUE_APP_WINE_IMAGE_PATH + wine.wines.images[0].name;
          }
        }
      }

      return wineimageurl;
    },

    getValuesForItem(investment, wine) {
      var isInLastSixMonths = "N";
      var orderDate = moment(investment.order_date);
      var sixMonthsAgo = moment().subtract(6, "months");
      if (sixMonthsAgo < orderDate) {
        isInLastSixMonths = "Y";
      }

      var buytotal =
        wine.quantity * wine.wines.units_per_case * wine.bottle_price;

      var total =
        parseInt(
          parseFloat(
            wine.quantity *
              wine.wines.units_per_case *
              wine.livePrices.data["price-average"]
          ) * 100
        ) / 100;

      if (wine.livePrices.data["price-average"] == 0) {
        total = buytotal;
      }

      if (isInLastSixMonths == "Y") {
        if (total < buytotal) {
          total = buytotal;
        }
      }

      var changePerc =
        ((parseFloat(total) - parseFloat(buytotal)) / parseFloat(buytotal)) *
        100;

      var changePrice = parseFloat(total) - parseFloat(buytotal);

      return {
        changePerc: changePerc,
        changePrice: changePrice,
        total: total,
        currency: investment.currency,
        buytotal: buytotal,
      };
    },

    getPrice(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.total;
    },

    getPurchase(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);
      return priceValues.buytotal;
    },

    getCurrency(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return priceValues.currency;
    },

    getChange(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      return (
        this.currencyFormatter(priceValues.changePrice) +
        "(" +
        this.currencyFormatter(priceValues.changePerc) +
        "%)"
      );
    },

    getChangeTotal(total) {
      var changePerc =
        (total.changePrice / (total.total - total.changePrice)) * 100;

      return (
        this.currencyFormatter(total.changePrice) +
        "(" +
        changePerc.toFixed(2) +
        "%)"
      );
    },

    priceUp(investment, wine) {
      var priceValues = this.getValuesForItem(investment, wine);

      if (priceValues.changePrice >= 0) {
        return true;
      } else {
        return false;
      }
    },

    currencyFormatter(value) {
      const options = {
        significantDigits: 2,
        thousandsSeparator: ",",
        decimalSeparator: ".",
        symbol: "",
      };

      if (typeof value !== "number") value = parseFloat(value);
      value = value.toFixed(options.significantDigits);

      const [currency, decimal] = value.split(".");
      return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator
      )}${options.decimalSeparator}${decimal}`;
    },
  },
};
</script>

<style>
#download_loader {
  width: 100px;
  height: 100px;
  background-image: url(/dotloader.svg);
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  margin: auto;
  background-size: 100%;
}
</style>
